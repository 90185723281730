import React from 'react'
import '../styles/Header.scss'

const Header = () => {

  return (
      <div className="header"><a href="https://mollieelisabethstein.com">Mollie Elisabeth Stein</a></div>
		)
}

export default Header