import React from 'react'
import '../styles/Main.scss'
import { about } from '../resources/Constants'

const About = () => {

  return (
      <div className="main"><p>{about}</p></div>
		)
}

export default About